<template>
  <component
    :is="component"
    class="th-px-8 th-px-16-lg"
    :to="routeTo"
    :href="to"
  >
    <img
      v-if="light"
      :src="require('@/assets/images/logos/logo_secondary.svg')"
      :alt="$global.brand"
      :height="height"
      class="app-logo"
    />
    <img
      v-else
      :src="require('@/assets/images/logos/logo_primary.svg')"
      :alt="$global.brand"
      :height="height"
      class="app-logo"
    />
  </component>
</template>

<script>
export default {
  name: "HeaderLogo",
  props: {
    height: {
      type: String,
      default: "56",
    },
    light: {
      type: Boolean,
      default: true,
    },
    to: {
      type: [String, Object],
    },
  },
  computed: {
    routeTo() {
      return this.to || { name: "home", params: this.$route.params };
    },
    component() {
      return this.href ? "a" : "router-link";
    },
    href() {
      return typeof this.to === "string" ? this.to : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-logo {
  width: auto;
  max-height: 100%;
}
</style>
