<template>
  <div class="d-flex flex-column align-items-center th-py-48">
    <template v-if="checkEmail">
      <h5
        class="th-mb-24"
        v-html="$translation.t('Great, check your inbox!')"
      ></h5>
      <p
        class="text-center"
        v-html="
          $translation.t(
            'We\'ve just sent you a link to {email} to reset your password. Click on the link in the email and follow the instructions',
            { email }
          )
        "
      />
      <v-btn
        color="secondaryDark"
        text
        small
        :loading="resetting"
        class="mx-auto th-mt-24"
        :disabled="!showResend"
        @click.native="forgotPassword()"
      >
        {{ $translation.t("Resend email") }}
      </v-btn>
    </template>
    <template v-else>
      <h5 class="th-mb-24" v-html="$translation.t('Reset your password')"></h5>
      <v-form ref="passwordReset" class="w-100">
        <v-text-field
          :label="$translation.t('Email')"
          :rules="[rules.email]"
          outlined
          id="email"
          name="email"
          v-model="emailInput"
          class="th-mb-12"
          hide-details="auto"
        ></v-text-field>
      </v-form>
      <p class="text-danger" v-if="error">{{ error }}</p>
      <v-btn
        color="primary"
        large
        block
        :loading="resetting"
        class="mx-auto th-mt-12"
        @click.native="forgotPassword()"
      >
        {{ $translation.t("Reset") }}
      </v-btn>
    </template>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";

export default {
  name: "ResetPassword",
  props: {
    email: String,
  },
  data: () => ({
    rules,
    error: "",
    emailInput: "mantas.daraciunas@gmail.com",
    resetting: false,
    checkEmail: false,
    showResend: false,
  }),
  created() {
    // this.emailInput = this.email || this.$route?.query?.email || "";
  },
  methods: {
    async forgotPassword() {
      this.resetting = true;
      try {
        await this.$axios.post("/api/v1/auth/password-reset", {
          email: this.emailInput,
          business: true,
        });

        setTimeout(() => {
          this.showResend = true;
        }, 10000);
      } catch (e) {
        this.$log.notify(e);
      }

      this.resetting = false;
      this.checkEmail = true;
    },
  },
};
</script>

<style lang="scss"></style>
