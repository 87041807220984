<template>
  <div v-if="token"></div>
  <div v-else class="container max-w-450">
    <div class="full-height top-center flex-column th-my-40">
      <div class="th-my-24">
        <header-logo :light="false" :to="homePageRoute" />
      </div>
      <div class="w-100">
        <th-card class="w-100 th-pb-16">
          <transition slot="body" name="slideRight">
            <reset-password :email="passemail" v-if="resetPassword" />
            <div v-else>
              <p class="th-mb-24 body2" v-if="$slots.title">
                <slot name="title" />
              </p>

              <google :on-success="onSuccess" />

              <div class="d-flex align-items-center th-my-24">
                <v-divider></v-divider>
                <small class="caption th-px-8">
                  {{ $translation.t("or") }}
                </small>
                <v-divider></v-divider>
              </div>

              <slot name="pwlogin" :reset-init="resetInit">
                <password-login :on-success="onSuccess" @reset="resetInit" />
              </slot>

              <slot name="footer"></slot>
            </div>
          </transition>
        </th-card>
        <p class="th-mt-36" v-html="$translation.t('login.disclaimer')"></p>
      </div>
    </div>
  </div>
</template>
<script>
import ThCard from "../../components/GeneralComponents/ThCard";
import ResetPassword from "../../components/Login/ResetPassword";
import Google from "./Auth/Google";
import PasswordLogin from "./PasswordLogin";
import HeaderLogo from "../../components/Layout/Header/HeaderLogo";
import { mapState } from "vuex";

export default {
  name: "AuthCard",
  components: { HeaderLogo, PasswordLogin, Google, ResetPassword, ThCard },
  props: {
    onSuccess: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    error: "",
    agree: true,
    resetPassword: false,
    passemail: "",
  }),
  computed: {
    homePageRoute() {
      return window.location.origin + `/${this.$route.params.locale || ""}`;
    },
    ...mapState({
      token: (state) => state.auth.token,
    }),
  },
  methods: {
    resetInit(data) {
      this.passemail = data;
      this.resetPassword = true;
    },
  },
};
</script>
