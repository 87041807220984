<template>
  <auth-card>
    <template v-slot:title>
      <span
        v-html="
          $translation.t('Register an account with {brand}', {
            brand: 'TermsHub',
          })
        "
      ></span>
    </template>

    <template v-slot:pwlogin>
      <password-login register />
    </template>

    <template v-slot:footer>
      <have-account-button />
    </template>
  </auth-card>
</template>

<script>
import AuthCard from "./AuthCard";
import HaveAccountButton from "./HaveAccountButton";
import PasswordLogin from "./PasswordLogin";

export default {
  name: "Register",
  components: { PasswordLogin, HaveAccountButton, AuthCard },
};
</script>

<style scoped></style>
