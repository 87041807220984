<template>
  <div>
    <v-form ref="passwordsignin">
      <v-text-field
        :label="$translation.t('Email')"
        :rules="[rules.email]"
        outlined
        id="email"
        name="email"
        v-model="passemail"
        class="mb-3"
        hide-details="auto"
      ></v-text-field>
      <v-text-field
        :label="$translation.t('Password')"
        :rules="[rules.required, rules.password8]"
        outlined
        id="password"
        name="password"
        type="password"
        v-model="password"
        class="mb-3"
        hide-details="auto"
      ></v-text-field>
    </v-form>
    <p class="text-danger th-my-16" v-if="passworderror">
      {{ passworderror }}
    </p>
    <a
      v-if="!register"
      href
      id="login_password_reset"
      @click.prevent="$emit('reset', passemail)"
      class="d-block th-mb-24 text-right"
    >
      <small class="caption text-secondary">
        {{ $translation.t("Forgot password?") }}
      </small>
    </a>
    <v-btn
      :id="`${register ? 'register' : 'login'}_password`"
      block
      color="primary"
      large
      :loading="passwordSending"
      @click.native="passwordLogin"
    >
      {{ $translation.t(register ? "Register" : "Login") }}
    </v-btn>
  </div>
</template>

<script>
import { login } from "../../utils/auth";
import rules from "../../utils/inputRules";

export default {
  name: "PasswordLogin",
  props: {
    onSuccess: {
      type: Function,
      default: null,
    },
    register: Boolean,
  },
  data() {
    return {
      rules,
      passwordSending: false,
      passworderror: null,
      passemail: "",
      password: "",
    };
  },
  methods: {
    async passwordLogin() {
      if (!this.$refs.passwordsignin.validate()) return;

      this.passwordSending = true;

      try {
        const data = await this.$axios.post("/api/v1/auth/password", {
          email: this.passemail,
          password: this.password,
          business: true,
        });

        await login(data, "password", this.onSuccess);
      } catch (e) {
        this.passworderror = e.response.data.message;
        this.$log.notify(e);
      }

      this.passwordSending = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
