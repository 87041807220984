import store from "../store";
import router from "../router";
import { eventLog } from "../global";
import $log from "./service/log";

export const login = async (data, type = null, callback = null) => {
  const { token, user_id } = data;

  // refresh storage
  await window?.localStorage?.removeItem(process.env.VUE_APP_STORAGE_NAME);

  try {
    store.commit("SET_TOKEN", token);
    store.commit("SET_USER_ID", user_id);

    await store.dispatch("clientDataIndex");
  } catch (e) {
    $log.notify(e);
  }

  try {
    eventLog("login", {
      method: type ? type : "Google",
    });
  } catch (e) {
    $log.notify(e);
  }

  if (callback) {
    callback({ token, userId: user_id });
    return;
  }

  const redirect = router.currentRoute.params.r;
  if (redirect) {
    return router.push(redirect);
  }

  await router.push({
    name: "auth",
    params: {
      ...router.currentRoute.params,
      locale: router.currentRoute.params?.locale,
    },
    query: {
      t: token,
      u: user_id,
    },
  });
  // window.location = `${window.location.origin}/auth?t=${token}&u=${user_id}`;
};
