<template>
  <div>
    <div class="th-mt-24 th-mb-12">
      <v-divider></v-divider>
    </div>

    <v-btn block color="secondaryDark" text @click="toLogin">
      {{ $translation.t("Have an account? Log in.") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "HaveAccountButton",
  props: {
    onLogin: {
      type: Function,
      default: null,
    },
    credentials: {},
  },
  methods: {
    toLogin() {
      if (this.onLogin && typeof this.onLogin === "function") {
        this.onLogin();
        return;
      }

      this.$router.push({
        name: "login",
        params: this.credentials,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
