<template>
  <div class="max-w-300 th-mx-auto">
    <div id="google-signin-btn"></div>
  </div>
</template>

<script>
import { login } from "../../../utils/auth";

export default {
  name: "Google",
  props: {
    onSuccess: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      windowObjectReference: null,
    };
  },
  beforeCreate() {
    let scriptTag = document.createElement("script");
    scriptTag.src = `https://accounts.google.com/gsi/client`;
    scriptTag.id = `google`;
    document.getElementsByTagName("head")[0].appendChild(scriptTag);
  },
  mounted() {
    // const vueInstance = this;
    const gapiInterval = setInterval(() => {
      if (
        // eslint-disable-next-line no-undef
        typeof google === "undefined" ||
        // eslint-disable-next-line no-undef
        typeof google.accounts === "undefined"
      )
        return;
      clearInterval(gapiInterval);

      // eslint-disable-next-line no-undef
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE,
        callback: this.onSignIn,
      });

      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(
        document.getElementById("google-signin-btn"),
        {
          theme: "outline",
          size: "large",
          shape: "pill",
          text: "continue_with",
          width: 300,
          locale: this.$route.params.locale === "lt" ? "lt" : "en",
        }
      );
    }, 500);
  },
  methods: {
    async onSignIn(response) {
      const data = await this.$axios.post("/api/v1/auth/verify-google", {
        idtoken: response.credential,
        business: true,
      });

      await login(data, "Google", this.onSuccess);
    },
  },
};
</script>

<style lang="scss" scoped></style>
